<template>
  <div class="activity">
    <header class="header">
      <h1>Virtual Screening</h1>
    </header>
    <main class="main">
      <table class="history_table">
        <thead class="history_table_head">
          <th class="table_head_item">Task ID</th>
          <th class="table_head_item">Target</th>
          <th class="table_head_item">Input Size</th>
          <th class="table_head_item">Task Name</th>
          <th class="table_head_item">Submit Time</th>
          <th class="table_head_item">Status</th>
          <th class="table_head_item">Options</th>
        </thead>
        <tbody>
          <tr class="table_row" v-for="history_item in historyArr" :key="history_item.id">
            <td class="history_item id">{{ history_item.id }}</td>
            <td class="history_item target">
              {{ history_item.chosen_category_or_target }}
            </td>
            <td class="history_item input_size">
              {{ history_item.input_size }}
            </td>
            <td class="history_item title">
              {{ history_item.title }}
            </td>
            <td class="history_item time">
              {{ transformTime(history_item.time) }}
            </td>
            <td class="history_item status">
              <div v-if="history_item.status == 0" class="status-item">
                <p style="background: skyblue">Computing</p>
              </div>
              <div v-else-if="history_item.status == 1" class="status-item">
                <p style="background: yellowgreen">Done</p>
              </div>
              <div v-else-if="history_item.status == 2" class="status-item">
                <p style="background: red">Fail</p>
              </div>
              <div v-else class="status-item">
                <p style="background: pink">Queuing</p>
              </div>
            </td>
            <td class="history_item options">
              <div class="btns">
                <button class="btn show-online" @click="toResultPage(history_item)"
                  :disabled="history_item.status != 1">
                  Detail
                </button>
                <button class="btn download_file" @click="downloadFile(history_item)"
                  :disabled="history_item.status != 1">
                  Download
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </main>
  </div>
</template>

<script>
import { historyApi } from "../../api/index";

export default {
  data() {
    return {
      historyArr: [],
    };
  },
  methods: {
    // 获取历史记录，并存入historyArr
    getHistoryItems() {
      historyApi
        .getHistory({
          service_type: "PRE",
          order_by: "time",
          ASC: false,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.historyArr = res.data.filter(item => item.type == 'SEL');
          }
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },

    // 跳转到结果页面
    toResultPage({ type, input_size, id, chosen_category_or_target }) {
      console.log(type, input_size, id, chosen_category_or_target);
      console.log("to virtual result page ", id);
      let routeData = this.$router.resolve({
        path: `/service/virtual/virtual-result/${id}`,
      });
      window.open(routeData.href, "_blank");
    },
    // 下载csv文件
    downloadFile(path) {
      // console.log(path);
      if (path.file) window.open(this.$store.state.baseUrl + path.file);
      // console.log(path);
      // window.open(`http://47.112.137.190:20193/` + path);
    },
    // 将时间转换成用户习惯的形式，如2022/2/2 12:00
    transformTime(time) {
      const tmp = new Date(time);
      return (
        tmp.getFullYear() +
        "/" +
        (tmp.getMonth() + 1) +
        "/" +
        (tmp.getDate() >= 10 ? tmp.getDate() : "0" + tmp.getDate()) +
        " " +
        (tmp.getHours() >= 10 ? tmp.getHours() : "0" + tmp.getHours()) +
        ":" +
        (tmp.getMinutes() >= 10 ? tmp.getMinutes() : "0" + tmp.getMinutes())
      );
    },
  },
  created() {
    this.getHistoryItems();
  },
  mounted() { },
};
</script>

<style scoped>
.activity {
  height: 100%;
  width: 100%;
}

.header {
  box-sizing: border-box;
  background: var(--color2-dark);
  width: 100%;
  color: black;
  padding: 10px;
  border-radius: 5px;
}

.main {
  box-sizing: border-box;
  width: 100%;
}

.history_table {
  margin: 50px 0;
  box-sizing: border-box;
  /* padding: 20px 50px; */
  width: 100%;
  overflow: hidden;
  color: black;
  /* border-collapse: collapse; */
}

.history_table_head {
  font-size: 20px;
  height: 50px;
  background-color: var(--color2-light);
}

.table_row:nth-child(2n) {
  background-color: var(--color2-light);
}

.table_row:nth-child(2n+1) {
  background-color: var(--color2-dark);
}

.history_item {
  text-align: center;
  /* width: fit-content; */
  height: 50px;
  font-size: 20px;
}

.btn {
  height: fit-content;
  font-size: 20px;
  background-color: var(--color1-light);
  color: white;
  padding: 5px;
  cursor: pointer;
}


.history_item.options .btns {
  display: flex;
  justify-content: space-around;
  /* align-items: ; */
}

.history_item.status .status-item {
  display: flex;
  justify-content: center;
  width: 100%;
}

.status-item p {
  color: white;
  padding: 5px;
  border-radius: 5px;
}
</style>
